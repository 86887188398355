import React from "react";
import TestHeader from "../TestHeader";
import { PingIco } from "../../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { IPing, IPingAll, ITestsResult } from "twillio-tests/core/TestResults";
import { useTranslation } from "react-i18next";

interface IPingTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
}

const initialValues: IPing = {
  ping: null,
  region: null,
};

const findBest = (arr: IPingAll): IPing | undefined => {
  if (Array.isArray(arr)) {
    const numbers = arr.map(({ ping }) => Number(ping));
    const best = Math.max.apply(Math, numbers);

    return arr.find((item) => {
      if (typeof item === "undefined") {
        return;
      }
      return Number(item.ping) === best;
    });
  }
};

const PingBestTest = (props: IPingTestProps) => {
  const { mainColor, testResult, theme, colorThresholds, knowledgeUrl, estimateTime, testName } =
    props;

  const pingTestResult = testResult ? testResult.pingTest : null;

  const pingTest = pingTestResult
    ? pingTestResult.best || (pingTestResult.all && findBest(pingTestResult.all))
    : initialValues;

  const currentThresholds = colorThresholds ? colorThresholds.Ping : null;

  const { ping, region, error } = pingTest;

  const defaultProps = {
    mainColor,
    color: "#0BA5EC",
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: "#FF494B",
    warningColor: "#FFB33F",
    successColor: "#0BA5EC",
  };
  const { t } = useTranslation(["common", "tests"]);

  return (
    <TestHeader
      icon={<PingIco />}
      title={t("tests:ping.title")}
      tooltip={t("tests:ping.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-ping">
        <table>
          <tbody>
            <tr className="tst-region">
              <td>{t("tests:ping.region")}</td>
              <td>
                <RenderValues {...defaultProps} value={region} />
              </td>
            </tr>
            <tr className="tst-rtt">
              <td>{t("tests:ping.rtt")}</td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={ping === -1 ? "error" : ping}
                  formatting={(value: number) => `${formatNumber(value)} <span>ms</span>`}
                  color={
                    currentThresholds && currentThresholds.ping
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: ping,
                            warningValue: currentThresholds.ping.warning,
                            errorValue: currentThresholds.ping.error,
                          },
                          currentThresholds.ping.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default PingBestTest;
