import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { TooltipIco, AvatourTooltipIco } from "../../icons";
import { ICoordinates, ITestsResult } from "twillio-tests/core/TestResults";
import Tooltip from "@material-ui/core/Tooltip";
function mapStateToProps(state: any) {
  return {
    testResult: state.tests.testResult,
    currentTheme: state.document.theme,
  };
}

interface ITestHeaderProps {
  icon: JSX.Element;
  title: string;
  tooltip: string;
  showInMap?: boolean;
  location?: {
    coordinates: ICoordinates | null;
  };
  testResult?: ITestsResult;
  error?: string;
  errorsCount?: number;
  children: any; // muly:TBD type
  knowledgeUrl?: string;
  currentTheme?: string;
  estimateTime?: number;
  testName?: string;
}

const TestHeader = (props: ITestHeaderProps) => {
  const {
    icon,
    title,
    tooltip,
    showInMap,
    location,
    testResult,
    error,
    children,
    knowledgeUrl,
    currentTheme,
    estimateTime,
    errorsCount,
    testName,
  } = props;
  const tooltipElem = (
    <>
      <div className="arrow" />
      {tooltip}
      {estimateTime && (
        <>
          <br />
          {`${estimateTime}s`}
        </>
      )}
    </>
  );

  let locationLink = "";
  if (location && location.coordinates) {
    const { latitude, longitude } = location.coordinates;
    locationLink = `https://maps.google.com/maps?ll=${latitude},${longitude}`;
  }
  const renderTooltipIcon = () => {
    return <TooltipIco />;
  };
  return (
    <>
      <div className="testsCardhead">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <g>
              <path d="M0,0h24 M24,24H0" fill="none" />
              <path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
              <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
          </svg>
        </span>
        <h2>{title}</h2>
        <Tooltip title={tooltipElem} placement="top">
          {knowledgeUrl !== "-" ? (
            <a href={knowledgeUrl} target="_blank" rel="noopener noreferrer">
              {renderTooltipIcon()}
            </a>
          ) : (
            renderTooltipIcon()
          )}
        </Tooltip>
        {showInMap && (
          <div>
            {
              <a href={locationLink} target="_blank" rel="noopener noreferrer">
                <Trans ns="tests" i18nKey="location.showOnMap">
                  Show <br /> on map
                </Trans>
              </a>
            }
          </div>
        )}
      </div>
      {!!error && (
        <div>
          <p>{error}</p>
        </div>
      )}
      {!error && children}
    </>
  );
};

export default connect(mapStateToProps, null)(TestHeader);
