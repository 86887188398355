import React from "react";
import i18n from "../i18n";
import { useAppSelector } from "../stores/hooks";
interface IHeaderProps {
  logo: string;
}

const Header = (props: IHeaderProps) => {
  const { logo } = props;
  console.log("🚀 ~ file: header.tsx:10 ~ Header ~ logo:", logo);
  const config = useAppSelector((state) => state.tests.config);
  const supportedLanguages: string[] =
    config && config.supportedLanguages ? config.supportedLanguages : [];
  return (
    <header className="main-header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col">
            <div className="logoblock">
              {logo && logo !== "" ? (
                <img src={logo} className="App-logo" alt="logo" />
              ) : (
                <img src="/img/agentassure_logo.jpeg" className="App-logo" alt="logo" />
              )}
              {/*  */}
            </div>
          </div>
          <div className="col-auto">
            <div className="languageSwitch">
              <span>
                <img src="/img/language_icon.svg" alt="language" />
              </span>
              <div className="c-select">
                <select onChange={(e) => i18n.changeLanguage(e.target.value)}>
                  {supportedLanguages.map((lng: string, key: number) => {
                    return (
                      <option key={key} value={lng}>
                        {config.languageTitles[lng]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
