import React from "react";
import { useTranslation } from "react-i18next";
import TestHeader from "../TestHeader";
import { DnsLookupIco } from "../../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { IDNSLookup, ITestsResult } from "twillio-tests/core/TestResults";

interface IDNSLookupTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
}

const initialValues = {
  error: undefined,
  totalConnections: undefined,
  successfulConnections: undefined,
  averageConnectionTime: undefined,
  highestConnectionTime: undefined,
  shortestConnectionTime: undefined,
};

const DNSLookupTest = (props: IDNSLookupTestProps) => {
  const { mainColor, testResult, theme, knowledgeUrl, estimateTime, colorThresholds, testName } =
    props;
  const dnsLookup: IDNSLookup =
    testResult && testResult.dnsLookup ? testResult.dnsLookup : initialValues;
  const currentThresholds = colorThresholds?.DNSLookup || null;
  const {
    error,
    meta,
    totalConnections,
    successfulConnections,
    averageConnectionTime,
    highestConnectionTime,
    shortestConnectionTime,
  } = dnsLookup;

  const defaultProps = {
    mainColor,
    color: "#0BA5EC",
    formatting: (value: number) => `${formatNumber(value)} <span>ms</span>`,
  };

  const colorTypes = {
    errorColor: "#FF494B",
    warningColor: "#FFB33F",
    successColor: "#0BA5EC",
  };

  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<DnsLookupIco />}
      title={t("tests:dns.title")}
      tooltip={t("tests:dns.title")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      errorsCount={meta?.errorsCount}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-dns-lookup">
        <table>
          <tbody>
            <tr className="tst-connected">
              <td>{t("connected")}</td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={successfulConnections}
                  formatting={(value: number) => `${value} of ${totalConnections}`}
                  color={
                    successfulConnections === totalConnections
                      ? defaultProps.color
                      : colorTypes.errorColor
                  }
                />
              </td>
            </tr>
            <tr className="tst-average-connection-time">
              <td>
                {t("average")} {t("connectionTime")}
              </td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={averageConnectionTime}
                  color={
                    currentThresholds && currentThresholds.averageConnectionTime
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: averageConnectionTime,
                            warningValue: currentThresholds.averageConnectionTime.warning,
                            errorValue: currentThresholds.averageConnectionTime.error,
                            warningValueMax: currentThresholds.averageConnectionTime.warningMax,
                            warningValueMin: currentThresholds.averageConnectionTime.warningMin,
                          },
                          currentThresholds.averageConnectionTime.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-highest-connection-time">
              <td>
                {t("highest")} {t("connectionTime")}
              </td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={highestConnectionTime}
                  color={
                    currentThresholds && currentThresholds.highestConnectionTime
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: highestConnectionTime,
                            warningValue: currentThresholds.highestConnectionTime.warning,
                            errorValue: currentThresholds.highestConnectionTime.error,
                            warningValueMax: currentThresholds.highestConnectionTime.warningMax,
                            warningValueMin: currentThresholds.highestConnectionTime.warningMin,
                          },
                          currentThresholds.highestConnectionTime.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-shortest-connection-time">
              <td>
                {t("shortest")} {t("connectionTime")}
              </td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={shortestConnectionTime}
                  color={
                    currentThresholds && currentThresholds.shortestConnectionTime
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: shortestConnectionTime,
                            warningValue: currentThresholds.shortestConnectionTime.warning,
                            errorValue: currentThresholds.shortestConnectionTime.error,
                            warningValueMax: currentThresholds.shortestConnectionTime.warningMax,
                            warningValueMin: currentThresholds.shortestConnectionTime.warningMin,
                          },
                          currentThresholds.shortestConnectionTime.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default DNSLookupTest;
