import React from "react";
interface IRenderValuesProps {
  value: string | number | null | undefined;
  mainColor?: string;
  classes?: any;
  color: string | null;
  tstId?: string;
  formatting?(value: number): string;
}

const styles = (theme: any) => ({
  value: {
    fontSize: 24,
    fontWeight: 600,
    color: "#0BA5EC",
    display: "block",
    "& span": {
      fontSize: 14,
      fontWeight: 400,
      color: "#8b909f",
    },
  },
  error: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1,
    color: "#FF494B",
    display: "block",
  },
  unavailable: {
    fontSize: 22,
    fontWeight: 600,
    color: "#8b909f",
    display: "block",
    lineHeight: 1,
  },
});

const RenderValuesWrapper = (props: IRenderValuesProps) => {
  const { value, classes, color, tstId } = props;

  if (
    value === undefined ||
    value === null ||
    color === null ||
    (typeof value === "number" && !Number.isFinite(value)) ||
    (typeof value === "number" && value === -1)
  ) {
    if (Number.isFinite(value) && Number(value) < 0) {
      return <span>N/A</span>;
    }
    return <span>-</span>;
  }

  let result = value;
  if (props.formatting && typeof value === "number") {
    result = props.formatting(value);
  }
  if (typeof result === "number") {
    return <span style={{ color: color ? color : "" }}>{result}</span>;
  }
  if (result === "error") {
    return <span>X</span>;
  }

  const title = result.replace(/\<.+\>(.+)\<\/.+\>/, "$1");

  return (
    <span
      title={title}
      className={`${tstId || "tst-value"}`}
      style={{ color: color ? color : "" }}
      dangerouslySetInnerHTML={{ __html: result }}
    />
  );
};

const RenderValues = RenderValuesWrapper;

export { RenderValues };
