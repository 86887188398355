import React, { Component } from "react";
import { TestNames } from "twillio-tests/config";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { Estimates } from "twillio-tests/estimates";
import { ITestsResult } from "twillio-tests/core/TestResults";

import ThroughputTest from "./Tests/ThroughputTest";
import CallQualityTest from "./Tests/CallQualityTest";
import NetworkInformation from "./Tests/NetworkInformationTest";
import TurnConnectivityTest from "./Tests/TurnConnectivityTest";
import LocationTest from "./Tests/LocationTest";
import LocationDetailedWidget from "./Tests/LocationDetailedWidget";
import BandwidthBestTest from "./Tests/BandwidthBestTest";
import BandwidthTestAll from "./Tests/BandwidthTestAll";
import PingTest from "./Tests/PingBestTest";
import PingTestAll from "./Tests/PingTestAll";
import VideoBandwidthTest from "./Tests/VideoBandwidthTest";
import DNSLookupTest from "./Tests/DNSLookupTest";
import RCVTURNServersTest from "./Tests/RCVTURNServersTest";
import VideoP2PTest from "./Tests/VideoP2PTest";
import DeviceStateTest from "./Tests/DeviceStateTest";

import "./TestsContainer.scss";
import PrivateConnectionsTest from "./Tests/PrivateConnectionsTest";
import { useAppSelector } from "../../stores/hooks";

interface ITestsContainerProps {
  testsList?: string[];
  classes?: any;
  config?: TestConfiguration;
  testResult?: ITestsResult;
  colorThresholds?: IColorThresholds;
}

const getEstimate = (config: TestConfiguration | undefined, test: string) => {
  if (config) {
    if (test === "VideoQuality" && (config as any).VideoQuality?.ConnectionInfoName === "webex") {
      return Estimates["WebexVideoQuality"];
    }
    if (test === "DNSLookup") {
      return config.domains?.length ? (config.domains.length * 200) / 1000 : 0;
    }
  }
  return Estimates[test];
};

const TestsContainer = (props: ITestsContainerProps) => {
  const {
    testsList = [],
    testResult,
    testScore,
    config,
  } = useAppSelector((state: any) => state.tests);
  const { colorThresholds } = useAppSelector((state: any) => state.document);
  const renderTests = () => {
    return testsList
      .filter((test: string) => {
        if (config && config[test]?.hidden && config?.isClientPortal) {
          return false;
        } else {
          return test;
        }
      })
      .map((test: string) => {
        const testName: TestNames = TestNames[test as keyof typeof TestNames];
        let element;
        const defaultProps = {
          pageSize: config?.pageSize || 3,
          testResult,
          colorThresholds,
          knowledgeUrl: config?.knowledgeUrl?.[test],
          estimateTime: getEstimate(config, test),
          testName: test,
          testTitle: config && config[test] ? config[test].title : false,
        };
        const wrapElements = (elements: any, someKey?: string) => {
          return (
            <div className="col-lg-4 col-md-6 mb-3">
              <div className="testsCard">{elements}</div>
            </div>
          );
        };
        switch (testName) {
          case TestNames.NetworkInformation:
            element = <NetworkInformation {...defaultProps} />;
            break;
          case TestNames.Throughput:
            element = <ThroughputTest {...defaultProps} config={config} />;
            break;
          case TestNames.CallQuality:
            element = <CallQualityTest {...defaultProps} config={config} />;
            break;
          case TestNames.TurnConnectivity:
            if (!config?.TurnConnectivity?.hide) {
              element = <TurnConnectivityTest {...defaultProps} config={config} />;
            } else {
              element = null;
            }
            break;
          case TestNames.Location:
            const showInMap =
              (config as any).Location?.showInMap === undefined
                ? true
                : (config as any).Location?.showInMap;
            const widgetVariant = (config as any).location?.widgetType;
            element =
              widgetVariant === "detailed" ? (
                <LocationDetailedWidget {...defaultProps} showInMap={showInMap} />
              ) : (
                <LocationTest {...defaultProps} showInMap={showInMap} />
              );
            break;
          case TestNames.BandwidthSpeed:
            element = (config as any).BandwidthSpeed?.all ? (
              <BandwidthTestAll
                {...defaultProps}
                onlyOneItem={config?.speedAllList && config.speedAllList.length === 1}
              />
            ) : (
              <BandwidthBestTest {...defaultProps} />
            );
            break;
          case TestNames.Ping:
            if (!config?.ping?.hide) {
              element = (config as any)?.Ping?.all ? (
                <PingTestAll {...defaultProps} />
              ) : (
                <PingTest {...defaultProps} />
              );
            } else {
              element = null;
            }
            break;
          case TestNames.VideoBandwidth:
            element = <VideoBandwidthTest {...defaultProps} />;
            break;
          case TestNames.VideoBandwidth2:
            element = <VideoBandwidthTest {...defaultProps} />;
            break;
          case TestNames.VideoP2P:
            element = <VideoP2PTest {...defaultProps} />;
            break;
          case TestNames.VideoQuality:
            element = <VideoP2PTest {...defaultProps} videoQualityMode={true} />;
            break;
          case TestNames.DNSLookup:
            element = <DNSLookupTest {...defaultProps} />;
            break;
          case TestNames.RCVTURNServers:
            element = <RCVTURNServersTest {...defaultProps} />;
            break;
          case TestNames.PrivateConnections:
            element = <PrivateConnectionsTest {...defaultProps} />;
            break;
          case TestNames.DeviceState:
            element = <DeviceStateTest {...defaultProps} />;
            break;
          default:
            element = null;
            break;
        }
        if (element === null) {
          return element;
        }
        return wrapElements(element);
      });
  };
  return (
    <div className="row">
      <>{renderTests()}</>
    </div>
  );
};

export default TestsContainer;
