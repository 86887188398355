import React from "react";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";

const getPermission = async (config: TestConfiguration) => {
  const onChange = async () => {
    const micId = config.options.micId;
    const camId = config.options.camId;
    let checkAudio = true;
    let checkVideo = true;
    if (config.getUserMedia) {
      checkAudio = config.getUserMedia.checkAudio ?? true;
      checkVideo = config.getUserMedia.checkVideo ?? true;
    }
    const constraints = {
      video: camId ? { deviceId: camId } : checkVideo,
      audio: micId
        ? {
            deviceId: micId,
          }
        : checkAudio,
    };
    console.log("🚀 ~ file: useGetMediaPermissions.ts:22 ~ onChange ~ constraints:", constraints);
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      const tracks = mediaStream.getTracks();
      //const deviceName: any = {};
      tracks.forEach((t: MediaStreamTrack) => {
        t.stop();
      });
      return true;
    } catch (error) {
      return false;
    }
  };
  const isPermissionGiven = await onChange();
  return isPermissionGiven;
};

export default getPermission;
