/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { initTests } from "../../actions/actions";
import { IDocumentState } from "../../interfaces";
import { ToastContainer } from "react-toastify";
import "../../App.scss";
import "react-toastify/dist/ReactToastify.css";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import Header from "../header";
import MainContent from "../mainContent";
import MessageModal from "../MessageModal";
interface IAppProps {
  document: IDocumentState;
  initTests(): void;
  config: TestConfiguration;
}

function mapStateToProps(state: any) {
  return {
    document: state.document,
    config: state.tests.config,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    initTests() {
      dispatch(initTests());
    },
  };
}

const App = (props: IAppProps) => {
  const {
    document: { isLoading, showPermissionError, linkExpired, invalidLink },
    initTests,
    config,
  } = props;
  React.useEffect(() => {
    initTests();
  }, []);

  React.useEffect(() => {
    if (!isLoading) {
      document.getElementById("root-spinner")?.remove();
    }
  }, [isLoading]);

  return (
    <div className="App">
      <Header logo={config ? config.branding?.logo : ""} />
      {!config?.invalidInvite &&
        !config?.message403 &&
        !config?.invalidMachine &&
        !linkExpired &&
        !invalidLink &&
        !showPermissionError && <MainContent />}

      {(config?.invalidInvite ||
        config?.message403 ||
        config?.invalidMachine ||
        linkExpired ||
        invalidLink ||
        showPermissionError) && (
        <MessageModal
          linkExpired={linkExpired}
          config={config}
          invalidLink={invalidLink}
          showPermissionError={showPermissionError}
        />
      )}
      <div>
        <div className="powered-by">
          <div>
            <span>Powered by</span>
            <img alt="Powered by" src="/img/powered-by-logo-svg.svg" width={92} />
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
