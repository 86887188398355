import React from "react";

const MessageModal = (props: any) => {
  const { config, linkExpired, invalidLink, showPermissionError } = props;

  return (
    <div>
      <div className="w-100 text-center p-2 max-w-600 mx-auto">
        <h2 className="fsr-38 m-3">Oops!</h2>
        {showPermissionError && (
          <p id="invalid_link" className="mb-5">
            We couldn't find a microphone It seems like there is no microphone configured or
            available on your device. We can't conduct this test.
          </p>
        )}
        {linkExpired && (
          <p id="invalid_link" className="mb-5">
            This link is expired. This test is designed for limited time use. Please reach out to IT
            Support for a usable link.
          </p>
        )}
        {(config?.invalidInvite || invalidLink) && (
          <p id="invalid_link" className="mb-5">
            This link isn't for you. This test is designed for individual use only. Please reach out
            to IT Support for a usable link.{" "}
          </p>
        )}
        {(config?.message403 || invalidLink) && (
          <p id="invalid_link" className="mb-5">
            This invitation link is no longer valid. Please reach out to IT Support for a usable
            link.{" "}
          </p>
        )}
        {config?.invalidMachine && (
          <p id="invalid_invite_token" className="mb-5">
            This is the wrong machine.<br></br>The test link is unique to a device and can only be
            conducted from the machine it ran on in the past. For more information please reach out
            to your IT support.
          </p>
        )}{" "}
        <div>
          <button
            onClick={() => window.open("https://www.spearline.com/support/#contact")}
            id="support_btn"
            type="button"
            className={`btn btn-primary test-btn px-5`}
          >
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
