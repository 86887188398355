import React, { useState } from "react";
import { useAppSelector } from "../stores/hooks";
import AdditionalTestsContainer from "./TestsContainer/AdditionalTestsContainer";
import { format, parseISO } from "date-fns";
import TestsContainer from "./TestsContainer/TestsContainer";
import LogModal from "./logsModal";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
interface ITestResultsProps {
  testScore: any;
  startTest: Function;
  isAccess: any;
}

const TestResults = (props: ITestResultsProps) => {
  const { testScore, startTest, isAccess } = props;
  const [showWidget, setShowWidget] = useState(false);
  const suggestions = testScore ? testScore.rulesEngineResult.issues : [];
  const config: TestConfiguration = useAppSelector((store: any) => store.tests.config);
  const uuid = useAppSelector((store: any) => store.document.uuid);
  const email = useAppSelector((store: any) => store.tests.email);
  const createDate = useAppSelector((store: any) => store.tests.createDate);
  const { details, widgets } = useAppSelector((store: any) => store.document.widgetPermission);
  const [open, setOpen] = useState(false);
  const getScore = () => {
    if (testScore) {
      const partOne = testScore?.scoringResult?._global?.scaledScore?.toString().split(".")[0] ?? 0;
      const partTwo = testScore?.scoringResult?._global?.scaledScore?.toString().split(".")[1];
      if (partTwo) {
        return `${partOne}.${partTwo.substring(0, 2)}`;
      } else {
        return partOne;
      }
    }
  };
  return (
    <div>
      {(config.displayDetailedView || details) && (
        <div className="tc-info tc-fixed py-3 mb-auto">
          <div className="user-details">User Details:</div>

          <div className="tc-inf-box">
            <div className="user-details-label">
              <span className="tc-inf-label">Email</span>
              <a href="#">{email}</a>
            </div>
            <div className="user-details-label">
              <span className="tc-inf-label">Runtime</span>
              <span className="runtime">
                {format(
                  parseISO(createDate ? createDate : new Date().toISOString()),
                  "MMM dd, yyyy - HH:mm"
                )}
              </span>
            </div>
          </div>
        </div>
      )}
      <div className={`results-circle ${testScore?.scoringResult._global.status}-status`}>
        <svg
          width="200"
          height="164"
          viewBox="0 0 200 164"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_8_34)">
            <path
              d="M166.671 160.456C170.763 164.166 177.133 163.883 180.416 159.442C189.888 146.627 196.191 131.691 198.734 115.862C201.807 96.7302 199.257 77.118 191.391 59.4094C183.526 41.7008 170.686 26.6586 154.431 16.1117C138.176 5.56474 119.208 -0.0326096 99.8311 0.000142918C80.4545 0.0328955 61.5047 5.69434 45.2857 16.2962C29.0667 26.898 16.2771 41.9835 8.47189 59.7186C0.666694 77.4537 -1.81789 97.0744 1.3202 116.195C3.91668 132.016 10.27 146.931 19.7859 159.713C23.0838 164.144 29.4548 164.405 33.5335 160.681C37.6122 156.957 37.8408 150.663 34.6547 146.152C27.6924 136.294 23.0247 124.951 21.0562 112.956C18.5457 97.6595 20.5334 81.9629 26.7776 67.7749C33.0217 53.5868 43.2534 41.5184 56.2286 33.0369C69.2038 24.5555 84.3636 20.0263 99.8649 20.0001C115.366 19.9739 130.541 24.4518 143.545 32.8893C156.549 41.3269 166.821 53.3606 173.113 67.5275C179.405 81.6944 181.446 97.3841 178.987 112.689C177.059 124.69 172.43 136.049 165.501 145.931C162.33 150.453 162.58 156.746 166.671 160.456Z"
              fill="#E2E2E2"
            />
          </g>
          <defs>
            <clipPath id="clip0_8_34">
              <rect width="200" height="164" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div>
          <h2>
            {testScore?.scoringResult._global.status === "fail"
              ? "Critical"
              : testScore?.scoringResult._global.status === "poor"
              ? "Fair"
              : "Good"}
          </h2>
          <h3>Voice Quality {getScore()}/5</h3>
        </div>
        {}{" "}
        {uuid && (!config?.options.invite || config?.options.invite === "") ? null : (
          <div className="refresh-btn">
            <button onClick={() => startTest()}></button>
          </div>
        )}
      </div>
      <div className="container">
        <h3 className="title-v2">
          {testScore?.scoringResult._global.status === "fail"
            ? "Lets work on this together"
            : testScore?.scoringResult._global.status === "poor"
            ? "Almost there but we can do better!"
            : "Great job you can effectively carry out voice calls!"}
        </h3>
        <ul className="results-info">
          {suggestions.map((suggestion: any, index: any) => {
            return (
              <li
                key={index}
                className={
                  suggestion.type === "Critical"
                    ? "alert-m"
                    : suggestion.type === "Fair"
                    ? "warning-m"
                    : "good-m"
                }
              >
                {suggestion.message}
              </li>
            );
          })}
        </ul>
      </div>
      {(config.displayDetailedView || widgets) && !showWidget && (
        <div id="show_more_btn" className="toggle-btn" onClick={() => setShowWidget(true)}>
          Show More
        </div>
      )}

      {(config.displayDetailedView || widgets) && showWidget && (
        <div className="tc-section">
          <div className="tc-info py-3">
            <div className="tc-inf-box">
              <div>
                <span>Result ID</span>
                <a href="#">{uuid}</a>
              </div>
            </div>
            <div className="tc-action">
              <button onClick={() => setOpen(true)}>LOGS</button>
            </div>
          </div>
          <LogModal open={open} setOpen={() => setOpen(false)} />
          <TestsContainer />
          <AdditionalTestsContainer />
        </div>
      )}
      {(config.displayDetailedView || widgets) && showWidget && (
        <div className="toggle-btn pt-3" id="show_less_btn" onClick={() => setShowWidget(false)}>
          Show Less{" "}
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="23">
              <path d="m283 711-43-43 240-240 240 239-43 43-197-197-197 198Z" />
            </svg>
          </span>
        </div>
      )}
    </div>
  );
};

export default TestResults;
