import React, { useState } from "react";
import TestHeader from "../TestHeader";
import { PingIco, ChevronIco } from "../../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatId, formatNumber, getColorValue } from "../../../helpers/utils";
import { IPing, ITestsResult } from "twillio-tests/core/TestResults";
import { useTranslation } from "react-i18next";

interface IPingTestAllProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  classes?: any;
  pageSize?: number;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
}

const initialValues: any = [];

const PingTestAll = (props: IPingTestAllProps) => {
  const {
    mainColor,
    testResult,
    theme,
    classes,
    colorThresholds,
    pageSize = 10,
    knowledgeUrl,
    estimateTime,
    testName,
  } = props;
  const pingTest: any = testResult && testResult.pingTest ? testResult.pingTest.all : initialValues;
  const currentThresholds = colorThresholds ? colorThresholds.Ping : null;

  const error = (pingTest as any).error;
  const [currentSlide, updateSlide] = useState(0);

  const defaultProps = {
    mainColor,
    color: "#0BA5EC",
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: "#FF494B",
    warningColor: "#FFB33F",
    successColor: "#0BA5EC",
  };
  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide + pageSize >= (pingTest as any).length;
  const isNeedScroll = !isFirstSlide || !isLastSlide;
  const { t } = useTranslation(["common", "tests"]);

  return (
    <TestHeader
      icon={<PingIco />}
      title={t("tests:pingtestall.title")}
      tooltip={t("tests:pingtestall.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-ping-all">
        {(pingTest as any).length ? (
          <>
            {isNeedScroll && (
              <div className={classes.slider}>
                <span
                  className={`${classes.prev} ${isFirstSlide ? classes.disabled : ""} tst-prev`}
                  onClick={() => updateSlide(currentSlide - pageSize)}
                >
                  <ChevronIco />
                  {t("tests:pingtestall.previous")}
                </span>
                <span
                  className={`${classes.next} ${isLastSlide ? classes.disabled : ""} tst-next`}
                  onClick={() => updateSlide(currentSlide + pageSize)}
                >
                  {t("tests:pingtestall.next")}
                  <ChevronIco />
                </span>
              </div>
            )}
            <table>
              <tbody>
                {pingTest
                  .slice(currentSlide, currentSlide + pageSize)
                  .map((test: IPing, i: number) => {
                    let region: any = test.region;
                    if (region && region.match(/\(.+\)/)) {
                      region = region.replace("(", "").replace(")", "");
                    }
                    return (
                      <tr key={i} className={formatId(region)}>
                        <td className="tst-name">{region || "Unknown"}</td>
                        <td>
                          <RenderValues
                            {...defaultProps}
                            value={test.ping === -1 ? "error" : test.ping}
                            formatting={(value: number) => `${formatNumber(value)} <span>ms</span>`}
                            color={
                              currentThresholds && currentThresholds.ping
                                ? getColorValue(
                                    {
                                      ...colorTypes,
                                      value: test.ping,
                                      warningValue: currentThresholds.ping.warning,
                                      errorValue: currentThresholds.ping.error,
                                    },
                                    currentThresholds.ping.isLess
                                  )
                                : defaultProps.color
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        ) : null}
      </div>
    </TestHeader>
  );
};

export default PingTestAll;
