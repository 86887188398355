import { toast } from "react-toastify";
import { ActionType } from "./actionTypes";
import DocumentService from "../services/DocumentService";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { sanitizeData } from "../helpers/utils";
import { ObjectID } from "bson";
export function startPreload() {
  return {
    type: ActionType.START_PRELOAD,
  };
}

export function setSendEmailStatus(status: string) {
  return {
    type: ActionType.UPDATE_SEND_EMAIL_STATUS,
    status,
  };
}

export function stopPreload() {
  return {
    type: ActionType.STOP_PRELOAD,
  };
}

function CreateLogMessage(dispatch: any) {
  return function logMessage(
    message: string | Error | any | any[],
    color: string = "black",
    _isSystemMessage: boolean = false
  ) {
    const time = Date.now();
    // Replace -1 value by "N/A"
    if (typeof message === "object") {
      Object.keys(message).forEach((key) => {
        if (typeof message[key] === "number" && message[key] === -1) {
          message[key] = "N/A";
        }
      });
    }

    dispatch({
      type: ActionType.LOG_MESSAGE,
      payload: {
        color,
        time,
        message,
      },
    });
  };
}

function logPingMessage() {
  // console.log(pingStatus);
}

function logSpeedMessage() {
  // console.log(message);
}

export function initTests() {
  return async (dispatch: any) => {
    dispatch(startPreload());
    dispatch(getLayout());
    // dispatch(updateColorThresholds(config.colorThresholds));
  };
}

export function getLayout() {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: ActionType.START_PRELOAD,
      });
      const result = await DocumentService.getLayout();
      if (result && typeof result === "object") {
        if (result.config.options.access && !result.config.displayDetailedView) {
          const permissions = await DocumentService.validateAccessToken(result.config);
          dispatch({
            type: ActionType.SET_WIDGETS_PERMISSION,
            widgetPermission: permissions,
          });
        }
        if (result.config.branding.favicon !== "") {
          const linkTag = document.createElement("link");
          linkTag.href = result.config.branding.favicon;
          linkTag.rel = "shortcut icon";
          document.head.appendChild(linkTag);
        } else {
          const linkTag = document.createElement("link");
          linkTag.href = "/img/Favicon16_16.png";
          linkTag.rel = "fav icon";
          document.head.appendChild(linkTag);
        }
        // prevent a page from appearing in Google Search
        if (result.config.disableSearchEngines) {
          const metaTag = document.createElement("meta");
          metaTag.name = "robots";
          metaTag.content = "noindex";
          document.head.appendChild(metaTag);
        }
        // const titleTag: any = document.querySelector("html > head > title");
        // titleTag.innerText = result.config.title;

        setTimeout(() => {
          dispatch({
            type: ActionType.GET_LAYOUT,
            payload: result,
          });
        }, 1);
        setTimeout(() => dispatch(stopPreload()), 1);
        setTimeout(() => dispatch(updateColorThresholds(result.config.colorThresholds)), 1);
      } else {
        let config = {};
        if (result) {
          if (result === 403) {
            config["message403"] = true;
          } else if (result === 404) {
            config["invalidInvite"] = true;
          } else if (result === 401) {
            config["invalidInvite"] = true;
          }
          dispatch(stopPreload());
          dispatch({
            type: ActionType.SET_INVALID_MACHINE,
            payload: config,
          });
        } else {
          setTimeout(() => dispatch(stopPreload()), 1);
          dispatch({ type: ActionType.SET_EXPIRED_LINK, linkExpired: true });
        }
      }
    } catch (err: any) {
      setTimeout(() => dispatch(stopPreload()), 1);
      console.log(err);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
}

function finishOneTest(
  dispatch: any,
  id: string,
  passedAtStart: number,
  passedAtEnd: number,
  totalTime: number,
  abort: Function
) {
  dispatch({
    type: ActionType.FINISH_ONE_TEST,
    payload: {
      id,
      passedAtStart,
      passedAtEnd,
      totalTime,
      abort,
    },
  });
}

const lazyLoadModule = async (
  awaitableImport: any,
  retries = 3,
  moduleFunction = "default"
): Promise<any> => {
  try {
    const module = await awaitableImport();
    return module[moduleFunction];
  } catch (err: any) {
    if (retries > 0) {
      const newRetries = retries - 1;
      console.log(`Lazy load module failed. Retries left: ${newRetries}`);
      await lazyLoadModule(awaitableImport, newRetries);
    } else {
      throw new Error(err.message);
    }
  }
};

export function startTest(testsList: string[], config: TestConfiguration) {
  return async (dispatch: any, getStore: any) => {
    try {
    } catch (err: any) {
      dispatch({
        type: ActionType.STOP_TESTS,
      });
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    dispatch({
      type: ActionType.RESET_TEST_RESULT,
    });
    dispatch({
      type: ActionType.SET_CUSTOM_ERROR,
      payload: null,
    });
    dispatch({
      type: ActionType.START_TESTS,
    });
    let result: any = null;

    const testRunId = new ObjectID().toString();
    try {
      const isValidated = await DocumentService.validateUserDevice(config);

      if (isValidated && typeof isValidated === "object") {
        dispatch({
          type: ActionType.SET_EMAIL,
          payload: isValidated.email,
        });
        const twillioTests = await lazyLoadModule(
          () => import("twillio-tests/tests"),
          3,
          "runAllTests"
        );

        result = await twillioTests(
          CreateLogMessage(dispatch),
          logPingMessage,
          logSpeedMessage,
          config,
          testsList,
          (
            id: string,
            passedAtStart: number,
            passedAtEnd: number,
            totalTime: number,
            abort: Function
          ) => finishOneTest(dispatch, id, passedAtStart, passedAtEnd, totalTime, abort)
        );

        result = sanitizeData(result);
        dispatch({
          type: ActionType.UPDATE_TEST_COUNT,
        });
        setTimeout(() => {
          dispatch({
            type: ActionType.UPDATE_TEST_RESULT,
            payload: result,
          });
          dispatch({
            type: ActionType.STOP_TESTS,
          });
        }, 1100);
      } else {
        if (isValidated) {
          if (isValidated === 403) {
            config["message403"] = true;
          } else if (isValidated === 404 || isValidated === 401) {
            config["invalidInvite"] = true;
          } else if (isValidated === 400) {
            config["invalidMachine"] = true;
          }
        } else {
          config["invalidMachine"] = true;
        }

        dispatch({
          type: ActionType.STOP_TESTS,
        });
        dispatch({
          type: ActionType.SET_INVALID_MACHINE,
          payload: config,
        });
      }
    } catch (err: any) {
      console.error(err.stack);
      dispatch({
        type: ActionType.STOP_TESTS,
      });
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    try {
      const store = getStore();
      const additionalFields = {
        account: store.tests.config?.options?.account,
        invite: store.tests.config?.options?.invite,
        email: store.tests?.email,
      };
      const context = config.options.context ? JSON.parse(config.options.context) : null;
      const validateContext = typeof context === "object" && context !== null ? context : {};
      const data = {
        ...validateContext,
        testResult: result,
        logs: store.tests.logs,
        ...sanitizeData(additionalFields),
      };
      if (result) {
        const uuid = await DocumentService.saveTestResult(testRunId, data, config);
        dispatch({
          type: ActionType.GET_UUID,
          payload: uuid._id,
        });
        dispatch({
          type: ActionType.SET_TEST_RESULT,
          payload: uuid,
        });
        const newUrl = `${uuid._id}${window.location.search}`;
        window.history.replaceState("", "", `${newUrl}`);
      }

      console.log("Save results is disabled", { config, data });
    } catch (err: any) {
      dispatch({
        type: ActionType.SET_CUSTOM_ERROR,
        payload: {
          error: err.message
            ? err.message
            : "Unable to save test result, check your network connection",
        },
      });
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
}

export function updateColorThresholds(colorThresholds: IColorThresholds | null) {
  return {
    type: ActionType.UPDATE_COLOR_THRESHOLDS,
    payload: colorThresholds,
  };
}
