import React, { Component } from "react";
import { connect } from "react-redux";
import { AdditionalTestNames } from "twillio-tests/config";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";

import "./TestsContainer.scss";
import VideoPlaybackTest from "./Tests/VideoPlaybackTest";
import { ITestsResult } from "twillio-tests/core/TestResults";
import BrowserCanRunTest from "./Tests/BrowserCanRunTest";
import AdditionalTestErrorBox from "./Tests/AdditionalTestErrorBox";
import GetUserMediaTest from "./Tests/GetUserMediaTest";

function mapStateToProps(state: any) {
  return {
    testsList: state.tests.testsList,
    testResult: state.tests.testResult,
    config: state.tests.config,
    colorThresholds: state.document.colorThresholds,
    currentTheme: state.document.theme,
    customError: state.document.customError,
  };
}

interface ITestsContainerProps {
  testsList?: string[];
  classes?: any;
  config?: TestConfiguration;
  testResult?: ITestsResult;
  colorThresholds?: IColorThresholds;
  currentTheme?: string;
  customError?: any;
}

class AdditionalTestsContainer extends Component<ITestsContainerProps> {
  renderTests() {
    const wrapElements = (elements: any, test?: string, someKey?: string) => {
      return (
        <div key={`${test} ${someKey}`}>
          <div>{elements}</div>
        </div>
      );
    };
    const { config, testsList = [], currentTheme } = this.props;
    const tests = testsList.map((test: string) => {
      const testName: AdditionalTestNames =
        AdditionalTestNames[test as keyof typeof AdditionalTestNames];
      let element;

      const defaultProps = {
        pageSize: config?.pageSize || 3,
        mainColor: "#0BA5EC",
        testResult: this.props.testResult,
        colorThresholds: this.props.colorThresholds,
        knowledgeUrl: config?.knowledgeUrl?.[test],
      };

      switch (testName) {
        case AdditionalTestNames.VideoPlayback:
          element = <VideoPlaybackTest {...defaultProps} />;
          break;
        case AdditionalTestNames.GetUserMedia:
          element = <GetUserMediaTest {...defaultProps} config={this.props.config} />;
          break;
        case AdditionalTestNames.DNSSetup:
          element = (
            <AdditionalTestErrorBox {...defaultProps} data={defaultProps.testResult?.dnsSetup} />
          );
          break;
        default:
          element = null;
          break;
      }
      if (element === null) {
        return element;
      }
      if (this.props.customError) {
        element = <AdditionalTestErrorBox {...defaultProps} data={this.props.customError} />;
      }
      return wrapElements(element, test);
    });

    return tests;
  }

  render() {
    const displayTests = this.props.testResult;

    return (
      <div
        style={{
          display: displayTests ? "block" : "none",
          border: "1px solid #c9cbd1",
          marginBottom: 15,
          pageBreakBefore: displayTests ? "always" : "unset",
        }}
      >
        {this.renderTests()}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(AdditionalTestsContainer);
