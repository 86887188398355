import { ActionType } from "../actions/actionTypes";
import { IDocumentState } from "../interfaces";
import { TestNames } from "twillio-tests/config";

const documentInitialState: IDocumentState = {
  isLoading: true,
  theme: null,
  testsCount: 0,
  currentTestCount: 0,
  currentTestsTime: null,
  testIsLoading: false,
  layout: {
    header: "",
    footer: "",
  },
  uuid: null,
  viewMode: false,
  colorThresholds: null,
  fields: [],
  sendEmailStatus: null,
  customError: null,
  testResults: null,
  linkExpired: false,
  invalidLink: false,
  showPermissionError: false,
  widgetPermission: {
    details: false,
    widgets: false,
  },
};

const document = (state: IDocumentState = documentInitialState, action: any) => {
  switch (action.type) {
    case ActionType.SET_INVALID_LINK:
      return {
        ...state,
        invalidLink: action.invalidLink,
      };
    case ActionType.SET_PERMISSION_ERROR:
      return {
        ...state,
        showPermissionError: action.showPermissionError,
      };
    case ActionType.START_PRELOAD:
      return { ...state, isLoading: true };
    case ActionType.STOP_PRELOAD:
      return { ...state, isLoading: false };
    case ActionType.SET_CUSTOM_ERROR:
      return { ...state, customError: action.payload };
    case ActionType.GET_LAYOUT: {
      const { config, uuid, viewMode } = action.payload;
      const testList = config.testsList;
      return {
        ...state,
        fields: config.fields,
        uuid,
        viewMode,
        theme: config.theme,
        testsCount: testList.length,
      };
    }
    case ActionType.SET_WIDGETS_PERMISSION:
      return { ...state, widgetPermission: action.widgetPermission };
    case ActionType.SET_EXPIRED_LINK:
      return { ...state, linkExpired: action.linkExpired };
    case ActionType.UPDATE_SEND_EMAIL_STATUS:
      return { ...state, sendEmailStatus: action.status };
    case ActionType.START_TESTS:
      return { ...state, testIsLoading: true };
    case ActionType.STOP_TESTS:
      return { ...state, testIsLoading: false, currentTestCount: 0 };
    case ActionType.UPDATE_TEST_COUNT:
      return { ...state, currentTestCount: state.testsCount };
    case ActionType.FINISH_ONE_TEST: {
      const increaseCount = action.payload.id ? 1 : 0;
      const count = state.currentTestCount + increaseCount;
      const currentTestsTime = action.payload;
      return { ...state, currentTestCount: count, currentTestsTime };
    }

    case ActionType.GET_UUID:
      return { ...state, uuid: action.payload };
    case ActionType.UPDATE_COLOR_THRESHOLDS:
      return { ...state, colorThresholds: action.payload };
    default:
      return state;
  }
};

export default document;
