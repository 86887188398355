import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { startTest } from "../actions/actions";
import { ActionType } from "../actions/actionTypes";
import { useAppDispatch, useAppSelector } from "../stores/hooks";
import StartTest from "./startTest";
import TestProgress from "./testProgress";
import TestResults from "./testResults";
import getPermission from "../helpers/customHooks/useGetMediaPermissions";
const MainContent = () => {
  const [isPermissionGiven, setPermissionStatus] = useState(false);
  const { isReady, testsList, config, testResult, testScore } = useAppSelector(
    (state: any) => state.tests
  );
  const dispatch = useAppDispatch();
  const testIsLoading = useAppSelector((store: any) => store.document.testIsLoading);
  // let isHideMessage = useMediaDevices(config);
  const { t } = useTranslation(["common", "tests"]);
  useEffect(() => {
    if (isPermissionGiven && testIsLoading) {
      dispatch(startTest(testsList, config));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPermissionGiven, testIsLoading]);
  return (
    <div>
      <div className="v-wrapper d-flex align-items-center">
        <div className="w-100 text-center py-5 my-2 px-4">
          {!isPermissionGiven && testIsLoading && (
            <div id="allow_popup" className="allowpopup">
              {`${t("click")}`} <span>{`${t("allow")}`}</span> {`${t("on_the_browser_popup")}`}{" "}
            </div>
          )}
          {(!isPermissionGiven || !testIsLoading) && isReady && !testResult && (
            <StartTest
              isHideMessage={isPermissionGiven}
              testIsLoading={testIsLoading}
              startTest={async () => {
                dispatch({
                  type: ActionType.START_TESTS,
                });
                const status = await getPermission(config);
                if (!status) {
                  dispatch({
                    type: ActionType.STOP_TESTS,
                  });
                  dispatch({
                    type: ActionType.RESET_TEST_RESULT,
                  });
                  dispatch({
                    type: ActionType.SET_PERMISSION_ERROR,
                    showPermissionError: true,
                  });
                }
                setPermissionStatus(status);
              }}
            />
          )}
          {testIsLoading && isPermissionGiven && <TestProgress />}
          {testResult && (
            <TestResults
              startTest={async () => {
                dispatch({
                  type: ActionType.START_TESTS,
                });
                const status = await getPermission(config);
                if (!status) {
                  dispatch({
                    type: ActionType.STOP_TESTS,
                  });
                  dispatch({
                    type: ActionType.RESET_TEST_RESULT,
                  });
                  dispatch({
                    type: ActionType.SET_PERMISSION_ERROR,
                    showPermissionError: true,
                  });
                }
                setPermissionStatus(status);
              }}
              isAccess={config.options.access}
              testScore={testScore}
            />
          )}
          {!testIsLoading && (
            <div>
              {" "}
              <button
                id="about_test_btn"
                onClick={() =>
                  window.open(
                    testResult
                      ? "https://www.spearline.com/knowledge-base/understanding-the-results/"
                      : "https://www.spearline.com/knowledge-base/running-a-test/"
                  )
                }
                type="submit"
                className="btn btn-primary-link  fsr-14 px-5 mt-4 "
              >
                {`${t("what_is_this_test")}`}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainContent;
