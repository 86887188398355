import React from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../stores/hooks";
interface IStartTest {
  startTest: Function;
  testIsLoading: Boolean;
  isHideMessage: boolean;
}

const StartTest = (props: IStartTest) => {
  const { config } = useAppSelector((state: any) => state.tests);
  const { startTest, testIsLoading, isHideMessage } = props;

  const { t } = useTranslation(["common", "tests"]);
  return (
    <button
      id="start_test_btn"
      type="button"
      onClick={() => startTest()}
      className={`btn btn-primary test-btn px-5 ${
        (!isHideMessage && testIsLoading) || !config ? "disabled" : ""
      }`}
    >
      {`${t("start")} ${t("test")}`}
    </button>
  );
};

export default StartTest;
