import React from "react";
import Scrollbars from "react-custom-scrollbars";
import Modal from "react-bootstrap/Modal";
import isArray from "lodash/isArray";
import startCase from "lodash/startCase";
import isNaN from "lodash/isNaN";
import isObject from "lodash/isObject";
import { ILog, LogColors } from "twillio-tests/core/TestResults";
import { useAppSelector } from "../stores/hooks";
import { format, parseISO } from "date-fns";
interface ILogModalProps {
  classes?: any;
  open: boolean;
  anchor?: string;
  setOpen(): void;
  uuid?: any;
}

interface IDispatch {
  theme?: string;
  logs?: ILog[];
  rulesEngineResult?: any;
}

const containsHtml = (messageText: any) =>
  /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(messageText);

const makeRow = (keyText: string, value: any, time: any) => {
  const hasHTML = containsHtml(value.toString());

  const valueP = hasHTML ? (
    <p dangerouslySetInnerHTML={{ __html: value.toString() }} />
  ) : (
    <p>{value.toString()}</p>
  );

  return (
    <tr key={keyText + value.toString() + Math.random() * 100}>
      <td>
        <p>{`${time ? `[${time}]` : ""}`}</p>
      </td>
      <td>
        <p>-</p>
      </td>
      <td>
        <p>{keyText}</p>
      </td>
      <td>{valueP}</td>
    </tr>
  );
};

// function to convert array to objects

const convertArrayToObject = (arr: Array<any>): any => {
  const obj = {};
  arr.forEach(function (a, index) {
    if (isObject(a)) {
      // check if value is object or string
      Object.keys(a).forEach((key) => {
        obj[key] = a[key];
      });
    } else {
      obj[index] = a;
    }
  });
  return obj;
};

const getRows = (object: any, time: any, color: LogColors, inner?: boolean): JSX.Element[] => {
  let result: any[] = [];
  Object.keys(object).forEach((key: string, index: number) => {
    const value = object[key];
    time = index === 0 && !inner ? time : "";

    if (isArray(value)) {
      if (isNaN(Number(key))) {
        const innerObjectHeaderRow = makeRow(`${startCase(key)}:`, "", time);
        result = result.concat(innerObjectHeaderRow);
      }

      const values = convertArrayToObject(value); // get values from array as object
      const innerRows = getRows({ ...values }, time, color, true);
      result = result.concat(innerRows);
      return;
    }

    if (isObject(value)) {
      const innerObjectHeaderRow = makeRow(`${startCase(key)}:`, "", time);
      result.push(innerObjectHeaderRow);
      const innerRows = getRows(value, time, color, true);
      result = result.concat(innerRows);
      return;
    }

    if (value !== undefined && value !== null) {
      const keyText = !inner
        ? `${startCase(key)}:`
        : inner && !isNaN(Number(key))
        ? `${startCase(key)}:`
        : `[${index + 1}] ${startCase(key)}:`;
      const row = makeRow(keyText, value, time);
      result.push(row);
    }
  });
  return result;
};

const LogModal = (props: ILogModalProps & IDispatch) => {
  const { logs } = useAppSelector((state: any) => state.tests);
  const refs = logs?.reduce((acc: any, value: any) => {
    acc[value.message] = React.createRef();
    return acc;
  }, {});

  const handleClick = (message: any) => {
    if (refs[message].current) {
      refs[message].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  //   React.useEffect(() => {
  //     if (anchor && logs && logs?.length > 0) {
  //       const testHeader = logs?.find(
  //         (x) => typeof x.message === "string" && x.message.indexOf(anchor) !== -1
  //       )?.message;

  //       if (testHeader) {
  //         setTimeout(() => {
  //           handleClick(testHeader);
  //         }, 100);
  //       }
  //     }
  //   }, [ handleClick, logs]);

  const plainMessage = (idx: number, message: string, time: any, color: LogColors) => {
    if (!message) {
      return null;
    }

    const messageText = typeof message === "string" ? message : (message as Error).message;

    // e.g. === Location ===
    const isTestHeader = messageText.includes("===");
    const isTestFooter = messageText.includes("End ===");

    if (containsHtml(messageText)) {
      return (
        <div
          key={idx}
          className={` ${isTestFooter ? "black" : isTestHeader ? "green" : color}`}
          dangerouslySetInnerHTML={{
            __html: ` [${time}]${isTestHeader ? " " : "  -  "}${messageText}`,
          }}
        ></div>
      );
    }

    return (
      <div key={idx} ref={refs[messageText]}>
        <div key={idx} className={` ${isTestFooter ? "black" : isTestHeader ? "green" : color}`}>
          {` [${time}]${isTestHeader ? " " : "  -  "}${messageText}`}
        </div>
      </div>
    );
  };

  const complexMessage = (idx: number, message: string, time: any, color: LogColors) => {
    const rows = getRows(message, time, color);
    return (
      <table key={idx}>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  return (
    <Modal className={`logModalMainFull`} show={props.open} onHide={props.setOpen}>
      <div className={`logModalMain`}>
        <span className="lmClose" onClick={props.setOpen}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </span>
        <Modal.Body>
          <div className="log-title">LOG</div>
          {props.uuid && <p>Test run id: {<strong>{props.uuid}</strong>}</p>}
          {
            <Scrollbars className="log-content" style={{ height: 480 }}>
              {logs?.map((log: ILog, i: number) => {
                const time =
                  typeof log.time === "number"
                    ? format(log.time, "d MMMM yyyy HH:mm:ss")
                    : log.time;
                if (isObject(log.message)) {
                  return complexMessage(i, log.message, time, log.color);
                }
                return plainMessage(i, log.message, time, log.color);
              })}
              {}
            </Scrollbars>
          }
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default LogModal;
