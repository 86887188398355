export const ActionType = {
  START_PRELOAD: "START_PRELOAD",
  STOP_PRELOAD: "STOP_PRELOAD",
  GET_LAYOUT: "GET_LAYOUT",
  LOG_MESSAGE: "LOG_MESSAGE",
  INIT_TESTS: "INIT_TESTS",
  UPDATE_TEST_RESULT: "UPDATE_TEST_RESULT",
  START_TESTS: "START_TESTS",
  STOP_TESTS: "STOP_TESTS",
  FINISH_ONE_TEST: "FINISH_ONE_TEST",
  RESET_TEST_RESULT: "RESET_TEST_RESULT",
  GET_UUID: "GET_UUID",
  UPDATE_COLOR_THRESHOLDS: "UPDATE_COLOR_THRESHOLDS",
  UPDATE_SEND_EMAIL_STATUS: "UPDATE_SEND_EMAIL_STATUS",
  SET_CUSTOM_ERROR: "SET_CUSTOM_ERROR",
  SET_EMAIL: "SET_EMAIL",
  SET_TEST_RESULT: "SET_TEST_RESULT",
  SET_INVALID_MACHINE: "SET_INVALID_MACHINE",
  SET_EXPIRED_LINK: "SET_EXPIRED_LINK",
  SET_WIDGETS_PERMISSION: "SET_WIDGETS_PERMISSION",
  SET_INVALID_LINK: "SET_INVALID_LINK",
  SET_PERMISSION_ERROR: "SET_PERMISSION_ERROR",
  UPDATE_TEST_COUNT: "UPDATE_TEST_COUNT",
};
