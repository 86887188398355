import React, { useEffect, useRef, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useAppSelector } from "../stores/hooks";
import * as rooks from "rooks";
interface ITestProgressProps {}

const TestProgress = () => {
  const [value, setValue] = useState(0);
  const counterRef = useRef({
    value: 0,
    start: 0,
  });
  const moveProgress = () => {
    counterRef.current.value += 1;
    setValue(value + 1);
  };
  const currentTestTime = useAppSelector((state: any) => state.document.currentTestsTime);
  const currentTestCount = useAppSelector((state: any) => state.document.currentTestCount);
  const totalTests = useAppSelector((state: any) => state.tests.testsList);
  rooks.useIntervalWhen(moveProgress, 1000, currentTestTime);
  const passedAtEnd = currentTestTime?.passedAtEnd || 0;
  const passedAtStart = currentTestTime?.passedAtStart || 0;
  const totalTime = currentTestTime?.totalTime || 0;
  if (counterRef.current.start !== passedAtStart) {
    // reset counter for new test
    counterRef.current.value = 0;
    counterRef.current.start = passedAtStart;
  }
  let progressBarPos = Math.round(
    (Math.min(passedAtEnd, passedAtStart + counterRef.current.value) / totalTime) * 100
  );
  if (progressBarPos === 100 && currentTestCount < totalTests.length) {
    progressBarPos = progressBarPos - 1;
  }

  return (
    <div>
      <div className="progress-circle" id="progress_bar">
        <CircularProgressbarWithChildren
          minValue={1}
          circleRatio={0.75}
          value={progressBarPos || 1}
          text={`${progressBarPos || 0}%`}
          styles={{
            text: {
              fill: "#243544",
              fontSize: "25px",
            },
            trail: {
              stroke: "#d6d6d6",
              transform: "rotate(-135deg)",
              transformOrigin: "center center",
            },
            path: {
              stroke: "#06B6DD",
              transform: "rotate(-135deg)",
              transformOrigin: "center center",
              transition: "stroke-dashoffset 0.5s ease 0s",
            },
          }}
        >
          <div style={{ paddingTop: "89px" }}>Testing</div>
        </CircularProgressbarWithChildren>
      </div>
      <div className="graph-label">This test will take 1-2 minutes to complete</div>
    </div>
  );
};

export default TestProgress;
