import React from "react";
import { useTranslation } from "react-i18next";
import TestHeader from "../TestHeader";
import { LocationIco } from "../../../icons";
import { RenderValues } from "../helpers/utils";
import { formatNumber } from "../../../helpers/utils";
import { IDeviceState, ITestsResult } from "twillio-tests/core/TestResults";

interface ILocationTestProps {
  testResult?: ITestsResult;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  showInMap?: boolean;
}

const initialValues = {
  audio: "-",
  device: "-",
  network: "-",
};

const DeviceStateTest = (props: ILocationTestProps) => {
  const { testResult, knowledgeUrl, estimateTime, testName, showInMap } = props;

  const deviceStateTest: IDeviceState =
    testResult && testResult.deviceState ? testResult.deviceState : initialValues;

  const region =
    testResult && testResult.testCall && testResult.testCall.region
      ? testResult.testCall.region
      : "-";

  const defaultProps = {
    mainColor: "#0BA5EC",
    color: "#0BA5EC",
    formatting: formatNumber,
  };

  const { t } = useTranslation(["common", "tests"]);

  const deviceName = deviceStateTest.device;
  const authorization =
    deviceStateTest.audio !== "-" ? t(`tests:devicestate.${deviceStateTest.audio}`) : "-";
  const network = deviceStateTest.network;

  return (
    <TestHeader
      icon={<LocationIco />}
      title={t("tests:devicestate.title")}
      tooltip={t("tests:devicestate.tooltip")}
      error={""}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min">
        <table>
          <tbody>
            <tr className="tst-city">
              <td>{t("tests:devicestate.audio")}</td>
              <td>{<RenderValues value={authorization} {...defaultProps} />}</td>
            </tr>
            <tr className="tst-country">
              <td>{t("tests:devicestate.device")}</td>
              <td>{<RenderValues value={deviceName} {...defaultProps} />}</td>
            </tr>
            <tr className="tst-org">
              <td>{t("tests:devicestate.network")}</td>
              <td>{<RenderValues value={network} {...defaultProps} />}</td>
            </tr>
            <tr className="tst-org">
              <td>{t("tests:devicestate.mediapath")}</td>
              <td>{<RenderValues value={region} {...defaultProps} />}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default DeviceStateTest;
