import React from "react";
import { useTranslation } from "react-i18next";
import TestHeader from "../TestHeader";
import { LocationIco } from "../../../icons";
import { RenderValues } from "../helpers/utils";
import { formatNumber } from "../../../helpers/utils";
import { ILocation, IMediaIp, ITestsResult } from "twillio-tests/core/TestResults";

interface ILocationTestProps {
  testResult?: ITestsResult;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  showInMap?: boolean;
}

const initialValues = {
  country: "",
  ip: "",
  organization: "",
  city: "",
  coordinates: { latitude: 0, longitude: 0 },
};

const LocationDetailedTestWidget = (props: ILocationTestProps) => {
  const { testResult, knowledgeUrl, estimateTime, testName, showInMap } = props;
  const locationTest: ILocation =
    testResult && testResult.location ? testResult.location : initialValues;
  const { coordinates } = locationTest;
  let { country, ip, organization, city, error } = locationTest;

  const mediaIpResult: IMediaIp =
    testResult && testResult.mediaIp ? testResult.mediaIp : initialValues;

  if (city === undefined) {
    city = "error";
  } else if (country === undefined) {
    country = "error";
  } else if (ip === undefined) {
    ip = "error";
  } else if (organization === undefined) {
    organization = "error";
  }

  const defaultProps = {
    mainColor: "#0BA5EC",
    color: "#0BA5EC",
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: "#FF494B",
    warningColor: "#FFB33F",
    successColor: "#0BA5EC",
  };

  const isVPN =
    locationTest.traits?.isAnonymous ||
    locationTest.traits?.isPublicProxy ||
    locationTest.traits?.isTorExitNode;
  const isHosted =
    locationTest.traits?.isHostingProvider && locationTest.traits.userType === "hosting";
  const ipColor = isVPN
    ? colorTypes.errorColor
    : isHosted
    ? colorTypes.warningColor
    : colorTypes.successColor;

  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<LocationIco />}
      title={t("location")}
      tooltip={t("tests:location.tooltip")}
      showInMap={error ? false : showInMap}
      location={{ coordinates }}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-location">
        <table>
          <tbody>
            <tr className="two-cols-mode-headers">
              <td></td>
              <td>Media</td>
              <td>Signalling</td>
            </tr>
            <tr className="tst-ip">
              <td>IP</td>
              <td>
                {
                  <RenderValues
                    {...defaultProps}
                    value={`${mediaIpResult.ip} ${
                      isVPN ? "<span>VPN</span>" : isHosted ? "<span>HOSTED</span>" : ""
                    }`}
                    color={ipColor}
                  />
                }
              </td>
              <td>
                {
                  <RenderValues
                    {...defaultProps}
                    value={`${ip} ${
                      isVPN ? "<span>VPN</span>" : isHosted ? "<span>HOSTED</span>" : ""
                    }`}
                    color={ipColor}
                  />
                }
              </td>
            </tr>
            <tr className="tst-city">
              <td>{t("city")}</td>
              <td>{<RenderValues value={city} {...defaultProps} />}</td>
              <td>{<RenderValues value={mediaIpResult.city} {...defaultProps} />}</td>
            </tr>
            <tr className="tst-country">
              <td>{t("country")}</td>
              <td>{<RenderValues value={country} {...defaultProps} />}</td>
              <td>{<RenderValues value={mediaIpResult.country} {...defaultProps} />}</td>
            </tr>
            <tr className="tst-org">
              <td>{t("org")}.</td>
              <td>{<RenderValues value={organization} {...defaultProps} />}</td>
              <td>{<RenderValues value={mediaIpResult.organization} {...defaultProps} />}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default LocationDetailedTestWidget;
