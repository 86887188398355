import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TestHeader from "../TestHeader";
import { BandwidthIco, ChevronIco } from "../../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { ISpeed, ISpeedAll, ITestsResult } from "twillio-tests/core/TestResults";

const Mbps = (value: string | number) => `${formatNumber(Number(value))} <span>Mbps</span>`;

interface IBandwidthTestAllProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  classes?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  onlyOneItem?: boolean;
  testName?: string;
}

const initialValues: ISpeed = {
  download: undefined,
  jitter: undefined,
  upload: undefined,
  region: undefined,
};

const BandwidthTestAll = (props: IBandwidthTestAllProps) => {
  const {
    mainColor,
    testResult,
    theme,
    classes,
    colorThresholds,
    knowledgeUrl,
    estimateTime,
    onlyOneItem,
    testName,
  } = props;
  const speedTest: ISpeedAll =
    testResult && testResult.speedTest ? testResult.speedTest.all : [initialValues];
  const currentThresholds = colorThresholds?.BandwidthSpeed || null;

  const [currentSlide, updateSlide] = useState(0);

  const error = (speedTest as any).error;
  const currentTest: ISpeed = speedTest[currentSlide];

  const { download, jitter, upload, region } = currentTest || initialValues;

  const defaultProps = {
    mainColor,
    color: "#0BA5EC",
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: "#FF494B",
    warningColor: "#FFB33F",
    successColor: "#0BA5EC",
  };
  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide === (speedTest as any).length - 1;
  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<BandwidthIco />}
      title={t("tests:bandwidth.title", { items: !onlyOneItem ? " (2)" : "" })}
      tooltip={t("tests:bandwidth.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-bandwidth">
        {!onlyOneItem && (
          <div className={classes.slider}>
            <span
              className={`${classes.prev} ${isFirstSlide ? classes.disabled : ""}`}
              onClick={() => updateSlide(currentSlide - 1)}
            >
              <ChevronIco />
              {t("previous")}
            </span>
            <span className={classes.name}>{region}</span>
            <span
              className={`${classes.next} ${isLastSlide ? classes.disabled : ""}`}
              onClick={() => updateSlide(currentSlide + 1)}
            >
              {t("next")}
              <ChevronIco />
            </span>
          </div>
        )}
        <table>
          <tbody>
            <tr className="tst-uplink">
              <td>{t("uplink")}</td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={upload}
                  formatting={Mbps}
                  color={
                    currentThresholds && currentThresholds.upload
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: upload,
                            warningValue: currentThresholds.upload.warning,
                            errorValue: currentThresholds.upload.error,
                          },
                          currentThresholds.upload.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-downlink">
              <td>{t("downlink")}</td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={download}
                  formatting={Mbps}
                  color={
                    currentThresholds && currentThresholds.download
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: download,
                            warningValue: currentThresholds.download.warning,
                            errorValue: currentThresholds.download.error,
                          },
                          currentThresholds.download.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-jitter">
              <td>{t("jitter")}</td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={jitter}
                  color={
                    currentThresholds && currentThresholds.jitter
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: jitter,
                            warningValue: currentThresholds.jitter.warning,
                            errorValue: currentThresholds.jitter.error,
                          },
                          currentThresholds.jitter.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default BandwidthTestAll;
