import axios from "axios";
import axiosRetry from "axios-retry";
import { toast } from "react-toastify";
import { getInstanceId, getParameters, IsLocalhost } from "../helpers/utils";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { ITestsResultToServer } from "twillio-tests/core/TestResults";
axiosRetry(axios, { retries: 3 });

const dev = false;

export default class DocumentService {
  static async getLayout() {
    const fingerPrint = getInstanceId();
    const uuid: any = window.location.pathname.replace("/", "");
    let options = getParameters();
    const report = options.report;

    let currentUuid = uuid;
    const viewMode = !!uuid;

    try {
      const netlifyBaseURL = IsLocalhost() ? "http://localhost:9000" : "";
      const answer = await axios.get(
        `${netlifyBaseURL}/.netlify/functions/get-config?config=${options.config || ""}&region=${
          options.region || null
        }&host=${options.host || null}`
      );
      let data = answer.data.config;
      if (typeof answer.data === "string") {
        // probebly invalid config JSON file so we recived as string and not as json, this will fail
        data = JSON.parse(answer.data);
      }
      let config: any = data;

      if (!uuid && (!options.invite || options.invite === "")) {
        config.invalidInvite = true;
        config.invalidInviteMessage = "This link is no longer valid. It has expired.";
      }
      let serverResults = null;
      try {
        if (uuid) {
          const result = await axios.get(
            `${netlifyBaseURL}/.netlify/functions/get-test-result?config=${
              config.branding?.subdomain || ""
            }&uuid=${uuid}`
          );
          serverResults = result.data.response;
        }
      } catch (err) {
        console.error(err.stack);
        currentUuid = null;
        return this.getErrorCode(err.response?.data?.errMessage);

        // toast.error(err.message, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
      }
      config.debug = false;
      config.location = answer.data.location;
      if (config.CallQuality) {
        config.CallQuality.useAudioFile = false;
      }
      if (options.debug && options.debug === "true") {
        console.log("===== Debug Mode =====");
        config.debug = true;
        config.disableAutoSave = true;
        config.fields = [];
        config.emailRequiredForStart = false;
        config.userRequirePassword = false;
        delete config.userAuthKey;
      }
      if (options.debug && options.debug === "test") {
        console.log("===== Test Mode =====");
        config.disableAutoSave = true;
        config.emailRequiredForStart = false;
        config.userRequirePassword = false;

        delete config.userAuthKey;
      }
      if (options.region) {
        console.log("===== Region: " + options.region + " =====");
        config.region = options.region;
      }
      if (options.save) {
        console.log("===== Save to new API key: " + options.save + " =====");
        config.API_KEY = options.save;
        config.API_URL = "https://api.testrtc.com/v1st/networktest"; // force staging
        config.disableAutoSave = false;
      }

      if (options.verbose) {
        config.verbose = options.verbose || "all";
      }
      const res = {
        fingerPrint,
        serverResults,
        config: { ...config, options },
        uuid: currentUuid,
        viewMode,
        report,
      };
      console.log("Test Configuration", { res });
      return res;
    } catch (err: any) {
      if (err.response?.data?.errMessage) {
        return this.getErrorCode(err.response?.data?.errMessage);
      } else {
        return false;
      }
    }
  }

  static async validateUserDevice(config: TestConfiguration) {
    try {
      const fingerPrint = config.fingerPrint;
      const inviteId = config.options?.invite;
      const configName = config.branding?.subdomain;
      const netlifyBaseURL = IsLocalhost() ? "http://localhost:9000" : "";
      const isValidated = await axios.get(
        `${netlifyBaseURL}/.netlify/functions/validate-user-device?configName=${configName}&inviteId=${inviteId}&fingerPrint=${fingerPrint}`
      );
      return isValidated.data.data.invite;
    } catch (error: any) {
      console.log(
        "🚀 ~ file: DocumentService.ts:121 ~ DocumentService ~ validateUserDevice ~ error:",
        error
      );
      if (error.response?.data?.errMessage) {
        return this.getErrorCode(error.response?.data?.errMessage);
      } else {
        return false;
      }
      //throw new Error(error);
    }
  }

  static getErrorCode(errorMessage: string) {
    if (errorMessage) {
      if (errorMessage.includes("403")) {
        return 403;
      } else if (errorMessage.includes("404")) {
        return 404;
      } else if (errorMessage.includes("401")) {
        return 401;
      } else if (errorMessage.includes("400")) {
        return 400;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  static async validateAccessToken(config: TestConfiguration) {
    try {
      const accessToken = config.options?.access;
      const configName = config.branding?.subdomain;
      const netlifyBaseURL = IsLocalhost() ? "http://localhost:9000" : "";
      const isValidated = await axios.get(
        `${netlifyBaseURL}/.netlify/functions/check-access?configName=${configName}&accessToken=${accessToken}`
      );
      return isValidated.data.data;
    } catch (error: any) {
      return false;
      //throw new Error(error);
    }
  }

  static async saveTestResult(testRunId: string, data: any, config: TestConfiguration) {
    try {
      const testData = {
        testId: testRunId,
        test: {
          invite: config.options?.invite,
          email: data.email,
          testResult: data.testResult,
          logs: data.logs,
        },
      };
      const netlifyBaseURL = IsLocalhost() ? "http://localhost:9000" : "";
      const testResults = await axios.post(
        `${netlifyBaseURL}/.netlify/functions/save-test-result`,
        JSON.stringify({ result: testData, config: config.branding?.subdomain })
      );
      return testResults.data.response;
    } catch (error) {}
  }

  static async sendResult(
    testRunId: string,
    data: ITestsResultToServer,
    config: TestConfiguration
  ) {
    const PATH = config.API_URL;
    const API_KEY = config.API_KEY;
    if (!PATH) {
      throw new Error("Missing config API_URL");
    }
    if (!API_KEY) {
      throw new Error("Missing config API_KEY");
    }

    try {
      console.log("sendResult", { data });
      const headerKey = dev ? "x-consumer-username" : "apikey";
      const apiData = { test: data, testId: testRunId };
      const result = await axios.post(`${PATH}/`, apiData, {
        headers: { [`${headerKey}`]: API_KEY },
      });
      console.log("sendResult", { status: result.status });
      return result;
    } catch (err: any) {
      console.error("sendResult", err.message, { err });
      throw new Error(err);
    }
  }
}
