import React from "react";
import { useTranslation } from "react-i18next";

import TestHeader from "../../TestHeader";
import { CallQualityIco } from "../../../../icons";
import { ICommonCallQulaityTestProps } from ".";
import { RenderValues } from "../../helpers/utils";
import { formatNumber, getColorValue } from "../../../../helpers/utils";

interface IDetailedProps extends ICommonCallQulaityTestProps {
  testCall?: any;
  theme?: any;
}

const Detailed = (props: IDetailedProps) => {
  const { theme, testCall, config, mainColor, currentThresholds } = props;
  console.log("🚀 ~ file: Detailed.tsx:17 ~ Detailed ~ currentThresholds", currentThresholds);

  const { avgJit, avgJitOut, avgPl, avgOutPl, avgRtt, mark, error, meta, mosInbound } = testCall;
  const colorTypes = {
    errorColor: "#FF494B",
    warningColor: "#FFB33F",
    successColor: "#0BA5EC",
  };

  const defaultProps = {
    mainColor,
    color: "#0BA5EC",
    formatting: formatNumber,
  };

  const overall =
    mark > 4 ? "Excellent" : mark > 3 ? "Good" : mark > 2 ? "Fair" : mark > 1 ? "Poor" : "Bad";

  // const overall2 =
  //   mark2 > 4 ? "Excellent" : mark2 > 3 ? "Good" : mark2 > 2 ? "Fair" : mark2 > 1 ? "Poor" : "Bad";

  const overall2 =
    mosInbound > 4
      ? "Excellent"
      : mosInbound > 3
      ? "Good"
      : mosInbound > 2
      ? "Fair"
      : mosInbound > 1
      ? "Poor"
      : "Bad";

  // this is outbound mos

  const mosValue =
    mark && mark != "N/A"
      ? `${mark.toFixed(1)} <span className="result-extra">(${overall})</span>`
      : "N/A";

  // const mosValue2 = mark2
  //   ? `${mark2.toFixed(1)} <span class=="result-extra">(${overall2})</span>`
  //   : "No Data";

  // this is inbound mos

  const mosValue2 =
    mosInbound && mosInbound != "N/A"
      ? `${mosInbound.toFixed(1)} <span className="result-extra">(${overall2})</span>`
      : "N/A";

  const { t } = useTranslation(["common", "tests"]);
  return (
    <TestHeader
      icon={<CallQualityIco />}
      title={t("tests:call-quality.title")}
      tooltip={t("tests:call-quality.tooltip")}
      error={error}
      knowledgeUrl={props.knowledgeUrl}
      estimateTime={props.estimateTime}
      testName={props.testName}
      errorsCount={meta?.errorsCount}
    >
      <div className={`tests-card-content tst-call-quality`}>
        <table>
          <tbody>
            <tr className="two-cols-mode-headers">
              <th></th>
              <th>{t("in")}</th>
              <th>{t("out")}</th>
            </tr>
            <tr className="tst-mos">
              <td>{t("MOS Score")}</td>
              <td>
                {
                  <RenderValues
                    {...defaultProps}
                    value={mosValue2}
                    color={
                      currentThresholds && currentThresholds.mark
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: mosInbound,
                              warningValue: currentThresholds.mark.warning,
                              errorValue: currentThresholds.mark.error,
                              warningValueMax: currentThresholds.mark.warningMax,
                              warningValueMin: currentThresholds.mark.warningMin,
                            },
                            currentThresholds.mark.isLess
                          )
                        : defaultProps.color
                    }
                  />
                }
              </td>
              <td>
                {
                  <RenderValues
                    {...defaultProps}
                    value={mosValue}
                    color={
                      currentThresholds && currentThresholds.mark
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: mark,
                              warningValue: currentThresholds.mark.warning,
                              errorValue: currentThresholds.mark.error,
                              warningValueMax: currentThresholds.mark.warningMax,
                              warningValueMin: currentThresholds.mark.warningMin,
                            },
                            currentThresholds.mark.isLess
                          )
                        : defaultProps.color
                    }
                  />
                }
              </td>
            </tr>
            <tr className="tst-round-trip">
              <td>{t("Round Trip")}</td>
              <td></td>
              <td>
                <RenderValues
                  {...defaultProps}
                  formatting={(value: number) => `${value} <span>ms</span>`}
                  value={avgRtt}
                  color={
                    currentThresholds && currentThresholds.avgRtt
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgRtt,
                            warningValue: currentThresholds.avgRtt.warning,
                            errorValue: currentThresholds.avgRtt.error,
                            warningValueMax: currentThresholds.avgRtt.warningMax,
                            warningValueMin: currentThresholds.avgRtt.warningMin,
                          },
                          currentThresholds.avgRtt.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-packet-loss">
              <td>{t("Packet Loss")}</td>
              <td>
                <RenderValues
                  {...defaultProps}
                  formatting={(value: number) => `${value.toFixed(1)}%`}
                  value={avgPl}
                  color={
                    currentThresholds && currentThresholds.avgPl
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgPl,
                            warningValue: currentThresholds.avgPl.warning,
                            errorValue: currentThresholds.avgPl.error,
                          },
                          currentThresholds.avgPl.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
              <td>
                <RenderValues
                  {...defaultProps}
                  formatting={(value: number) => `${value.toFixed(1)}%`}
                  value={avgOutPl}
                  color={
                    currentThresholds && currentThresholds.avgPl
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgOutPl,
                            warningValue: currentThresholds.avgPl.warning,
                            errorValue: currentThresholds.avgPl.error,
                          },
                          currentThresholds.avgPl.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr className="tst-jitter">
              <td>{t("jitter")}</td>
              <td>
                <div className="value-row-pair">
                  <RenderValues
                    value={avgJit}
                    {...defaultProps}
                    color={
                      currentThresholds && currentThresholds.avgJit
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgJit,
                              warningValue: currentThresholds.avgJit.warning,
                              errorValue: currentThresholds.avgJit.error,
                            },
                            currentThresholds.avgJit.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </div>
              </td>
              <td>
                <div className="value-row-pair">
                  <RenderValues
                    value={avgJitOut}
                    {...defaultProps}
                    color={
                      currentThresholds && currentThresholds.avgJit
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgJitOut,
                              warningValue: currentThresholds.avgJit.warning,
                              errorValue: currentThresholds.avgJit.error,
                            },
                            currentThresholds.avgJit.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default Detailed;
