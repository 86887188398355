import React from "react";
import { ITestsResult, IGetUserMedia } from "twillio-tests/core/TestResults";
import * as _ from "lodash";

interface IGetUserMediaProps {
  testResult?: ITestsResult;
  config?: any;
}

const GetUserMediaTest = (props: IGetUserMediaProps) => {
  const { testResult, config } = props;

  const getUserMedia: IGetUserMedia | undefined = testResult?.getUserMedia;

  const defaultProps = {
    mainColor: "#0BA5EC",
    color: "#0BA5EC",
    errorColor: "#FF494B",
  };

  const error = getUserMedia?.error;

  if (!getUserMedia) {
    return null;
  }

  return (
    <>
      {error && (
        <div className="tests-card-content tests-card-content--min additional-tests-card-content tst-get-user-media">
          {_.isArray(error) ? (
            <>
              {error.map((e) => (
                <div key={e} style={{ color: defaultProps.errorColor }}>
                  {e}
                </div>
              ))}
            </>
          ) : (
            <div style={{ color: defaultProps.errorColor }}>{error}</div>
          )}
        </div>
      )}
    </>
  );
};

export default GetUserMediaTest;
