import React from "react";
import { useTranslation } from "react-i18next";
import TestHeader from "../TestHeader";
import { VideoQualityIco, VideoP2PIco } from "../../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { IVideoP2P, ITestsResult } from "twillio-tests/core/TestResults";

interface IVideoP2PTestTest {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  videoQualityMode?: boolean;
  testName?: string;
}

const initialValues: IVideoP2P = {
  bandwidthEstimateIn: undefined,
  bandwidthEstimateOut: undefined,

  bitrate: undefined,
  bitrateOut: undefined,

  roundTripTime: undefined,

  fractionLoss: undefined,
  fractionLossOut: undefined,
};

const VieoP2PTest = (props: IVideoP2PTestTest) => {
  const {
    mainColor,
    testResult,
    theme,
    colorThresholds,
    knowledgeUrl,
    estimateTime,
    videoQualityMode,
    testName,
  } = props;

  const results =
    (testResult && testResult[videoQualityMode ? "videoQualityTest" : "videoP2P"]) || initialValues;

  const currentThresholds = videoQualityMode
    ? colorThresholds?.VideoQuality
    : colorThresholds?.VideoP2P;

  const {
    error,
    bandwidthEstimateOut,
    bitrate,
    bitrateOut,
    roundTripTime,
    fractionLoss,
    fractionLossOut,
    meta,
  } = results;

  const defaultProps = {
    mainColor,
    color: "#0BA5EC",
    formatting: formatNumber,
  };

  const colorTypes = {
    errorColor: "#FF494B",
    warningColor: "#FFB33F",
    successColor: "#0BA5EC",
  };

  const { t } = useTranslation(["common", "tests"]);
  const title = videoQualityMode ? t("tests:video.quality") : t("tests:video.p2p");

  return (
    <TestHeader
      icon={videoQualityMode ? <VideoQualityIco /> : <VideoP2PIco />}
      title={title}
      tooltip={title}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
      errorsCount={meta?.errorsCount}
    >
      <div className="tests-card-content tests-card-content--throughput tst-throughput">
        <table>
          <thead>
            <tr>
              <th />
              <td>{t("in")}</td>
              <td>{t("out")}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t("estimated")}</td>
              <td></td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={bandwidthEstimateOut}
                  formatting={(value: string | number) =>
                    `${formatNumber(Number(value))} <span>kbps</span>`
                  }
                  color={
                    currentThresholds && currentThresholds.bandwidthEstimateOut
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: bandwidthEstimateOut,
                            warningValue: currentThresholds.bandwidthEstimateOut.warning,
                            errorValue: currentThresholds.bandwidthEstimateOut.error,
                          },
                          currentThresholds.bandwidthEstimateOut.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr>
              <td>{t("bitrate")}</td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={bitrate}
                  formatting={(value: string | number) =>
                    `${formatNumber(Number(value))} <span>kbps</span>`
                  }
                  color={
                    currentThresholds && currentThresholds.bitrate
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: bitrate,
                            warningValue: currentThresholds.bitrate.warning,
                            errorValue: currentThresholds.bitrate.error,
                          },
                          currentThresholds.bitrate.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={bitrateOut}
                  formatting={(value: string | number) =>
                    `${formatNumber(Number(value))} <span>kbps</span>`
                  }
                  color={
                    currentThresholds && currentThresholds.bitrateOut
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: bitrateOut,
                            warningValue: currentThresholds.bitrateOut.warning,
                            errorValue: currentThresholds.bitrateOut.error,
                          },
                          currentThresholds.bitrateOut.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr>
              <td>{t("Round Trip")}</td>
              <td></td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={roundTripTime}
                  formatting={(value: string | number) =>
                    `${formatNumber(Number(value))} <span>ms</span>`
                  }
                  color={
                    currentThresholds && currentThresholds.roundTripTime
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: roundTripTime,
                            warningValue: currentThresholds.roundTripTime.warning,
                            errorValue: currentThresholds.roundTripTime.error,
                          },
                          currentThresholds.roundTripTime.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
            <tr>
              <td>{t("Packet Loss")}</td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={fractionLoss}
                  formatting={(value: string | number) =>
                    `${formatNumber(Number(value))} <span>%</span>`
                  }
                  color={
                    currentThresholds && currentThresholds.fractionLoss
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: fractionLoss,
                            warningValue: currentThresholds.fractionLoss.warning,
                            errorValue: currentThresholds.fractionLoss.error,
                          },
                          currentThresholds.fractionLoss.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
              <td>
                <RenderValues
                  {...defaultProps}
                  value={fractionLossOut}
                  formatting={(value: string | number) =>
                    `${formatNumber(Number(value))} <span>%</span>`
                  }
                  color={
                    currentThresholds && currentThresholds.fractionLossOut
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: fractionLossOut,
                            warningValue: currentThresholds.fractionLossOut.warning,
                            errorValue: currentThresholds.fractionLossOut.error,
                          },
                          currentThresholds.fractionLossOut.isLess
                        )
                      : defaultProps.color
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TestHeader>
  );
};

export default VieoP2PTest;
