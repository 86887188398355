import { ActionType } from "../actions/actionTypes";
import { ITestsState } from "../interfaces";

const testsInitialState: ITestsState = {
  isReady: true,
  testsList: [],
  logs: [],
  testResult: null,
  config: null,
  rulesEngineResults: null,
  cancelable: false,
  textDictionary: {},
  email: "",
  title: "",
  testScore: null,
};

const tests = (state: ITestsState = testsInitialState, action: any) => {
  switch (action.type) {
    case ActionType.INIT_TESTS:
      return { ...state, isReady: true };
    case ActionType.LOG_MESSAGE: {
      const newLogs = [...state.logs];
      const { message, color, time } = action.payload;
      newLogs.push({
        message,
        color,
        time,
      });
      return { ...state, logs: newLogs };
    }
    case ActionType.UPDATE_TEST_RESULT:
      return { ...state, testResult: action.payload };
    case ActionType.GET_LAYOUT: {
      let testResult = null;
      let logs: any[] = [];
      let email = "";
      let title = "";
      let rulesEngineResults = null;
      let createDate = null;
      if (action.payload.serverResults) {
        testResult = action.payload.serverResults.data.testResult;
        rulesEngineResults = action.payload.serverResults.rulesEngineResult?.issues;
        logs = action.payload.serverResults.data.logs;
        email = action.payload.serverResults.data.email;
        createDate = action.payload.serverResults.createDate;
      }

      let config = action.payload.config;
      config["fingerPrint"] = action.payload.fingerPrint;

      const newState = {
        ...state,
        testResult,
        logs,
        email,
        title,
        createDate,
        rulesEngineResults,
        ...action.payload.serverResults,
        testScore: action.payload.serverResults
          ? {
              rulesEngineResult: action.payload.serverResults.rulesEngineResult,
              scoringResult: action.payload.serverResults.scoringResult,
            }
          : null,
        config,
        cancelable: action.payload.config.cancelable,
        textDictionary: action.payload.config.textDictionary,
        testsList: action.payload.config.testsList,
      };
      return newState;
    }
    case ActionType.SET_EMAIL: {
      return { ...state, email: action.payload };
    }
    case ActionType.SET_INVALID_MACHINE: {
      return { ...state, config: action.payload };
    }
    case ActionType.SET_TEST_RESULT:
      return { ...state, testScore: action.payload };
    case ActionType.RESET_TEST_RESULT:
      return { ...state, logs: [], testResult: null };
    default:
      return state;
  }
};
export default tests;
