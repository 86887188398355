import React from "react";
interface IProps {
  mainColor?: string;
  data?: any;
  theme?: any;
}

const AdditionalTestErrorBox = (props: IProps) => {
  const { mainColor, data } = props;

  const defaultProps = {
    mainColor,
    color: "#0BA5EC",
    errorColor: "#FF494B",
  };

  const error = data?.error;

  return (
    <>
      {error && (
        <div className="tests-card-content tests-card-content--min additional-tests-card-content">
          <span style={{ color: defaultProps.errorColor }}>{error}</span>
        </div>
      )}
    </>
  );
};

export default AdditionalTestErrorBox;
